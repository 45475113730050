import React, { useState } from "react";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import Slider1 from "../img/gallery/Slider1.jpg";
import Slider2 from "../img/gallery/Slider2.jpg";
import Slider3 from "../img/gallery/Slider3.jpg";
import Slider4 from "../img/gallery/Slider4.jpg";
import Slider5 from "../img/gallery/Slider5.jpg";
import Slider6 from "../img/gallery/Slider6.jpg";
import Slider7 from "../img/gallery/Slider7.jpg";
import Slider8 from "../img/gallery/Slider8.jpg";
import Slider9 from "../img/gallery/Slider9.jpg";
import Slider10 from "../img/gallery/Slider10.jpg";
import Slider11 from "../img/gallery/Slider11.jpg";
import Slider12 from "../img/gallery/Slider12.jpg";
import Slider13 from "../img/gallery/Slider13.jpg";
import Slider14 from "../img/gallery/Slider14.jpg";
import Slider15 from "../img/gallery/Slider15.jpg";
import Slider16 from "../img/gallery/Slider16.jpg";
import Slider17 from "../img/gallery/Slider17.jpg";

const sliderimg = [
  { src: Slider1, alt: "", title: "slider 1" },
  { src: Slider2, alt: "", title: "slider 2" },
  { src: Slider3, alt: "", title: "slider 3" },
  { src: Slider4, alt: "", title: "slider 4" },
  { src: Slider5, alt: "", title: "slider 5" },
  { src: Slider6, alt: "", title: "slider 6" },
  { src: Slider7, alt: "", title: "slider 7" },
  { src: Slider8, alt: "", title: "slider 8" },
  { src: Slider9, alt: "", title: "slider 9" },
  { src: Slider10, alt: "", title: "slider 10" },
  { src: Slider11, alt: "", title: "slider 11" },
  { src: Slider12, alt: "", title: "slider 12" },
  { src: Slider13, alt: "", title: "slider 13" },
  { src: Slider14, alt: "", title: "slider 14" },
  { src: Slider15, alt: "", title: "slider 15" },
  { src: Slider16, alt: "", title: "slider 16" },
  { src: Slider17, alt: "", title: "slider 17" },
];

const Slider = () => (
  <div>
    {sliderimg.map((slide, index) => (
      <img
        key={index}
        src={slide.src}
        alt={slide.alt}
        title={slide.title}
        className="md:w-full sm:w-full h-full object-cover"
      />
    ))}
  </div>
);

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = (currentIndex - 1 + sliderimg.length) % sliderimg.length;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex + 1) % sliderimg.length;
    setCurrentIndex(newIndex);
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={transition1}
      className="section"
      style={{
        minHeight: "100vh",
        paddingTop: "140px",
        paddingBottom: "50px",
        overflowY: "auto",
      }}
    >
      <div className="container mx-auto py-2">
        <div className="flex flex-col lg:flex-row h-full justify-center gap-x-24 text-center lg:text-left lg:pt-2 lg:mt-[-60px] mt">
          <div className="flex-1 flex-col max-h-96 lg:max-h-max order-2 lg:order-none overflow-hidden">
            <div className="flex-1 max-w h-[600px] order-2 m-auto py-16 px-4 relative group lg:mt-32 mt-[-100px]">
              <div
                className="w-full h-full rounded-2xl bg-cover bg-center duration-500s"
                style={{
                  backgroundImage: `url(${sliderimg[currentIndex].src})`,
                }}
              ></div>
              {/* left arrow */}
              <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer">
                <BsChevronCompactLeft onClick={prevSlide} size={30} />
              </div>
              {/* right arrow */}
              <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer">
                <BsChevronCompactRight onClick={nextSlide} size={30} />
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: "-80%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-80%" }}
            transition={transition1}
            className="flex-1 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start"
          >
            <h1 className="h1">My Creations</h1>
            <p className="max-w-sm mb-4">
              An elegant portfolio of my work. For quotations and base prices
              you can message me here or via FB.
            </p>
            <a
              className="btn"
              href="https://www.facebook.com/profile.php?id=61556814742222"
              target="_blank"
              rel="noopener noreferrer"
            >
              VIEW MORE ON FB
            </a>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Gallery;

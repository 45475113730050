import React from "react";
import Testimonials from "../components/Testimonials";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import StickyButton from "../components/StickyButton";

const Testimonialpage = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={transition1}
        className="hidden lg:flex bg-gradient-to-b from-white to-[#FFD1DC] absolute bottom-0 left-0 right-0 top-72 -z-10"
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={transition1}
        className=" pt-[300px] lg:pl-20 pl-2"
      >
        <h1 className="h1 lg:mt-1 mt-[-100px]">They're Talking...</h1>

        <div className=" p-8 rounded-lg">
          {/* Apply gradient background to this div */}
          <Testimonials />
        </div>
        <StickyButton facebookPageId="248822661645650" />
      </motion.div>
    </>
  );
};

export default Testimonialpage;

import React, { useRef } from "react";
import ContactImg from "../img/contact/contact1.png";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import { ImFacebook } from "react-icons/im";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hux5vme_rosee",
        "template_94mzwgp_rosee",
        form.current,
        {
          publicKey: "xFX7lbU3Y_kHT7BOV",
        }
      )
      .then(
        () => {
          toast.success("Message Sent!");
        },
        (error) => {
          toast.error("FAILED...", error.text);
        }
      );
    e.target.reset();
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={transition1}
      className="section bg-white relative"
    >
      <div className="container mx-auto h-full">
        <div className="flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left">
          <motion.div
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "100%" }}
            transition={transition1}
            className="hidden lg:flex bg-[#eef7f9] absolute bottom-0 left-0 right-0 top-72 -z-10"
          ></motion.div>
          <div className="lg:flex-1 lg:pt-32 lg:mb-1 mb-4 px-4">
            <h1 className="h1">Contact me</h1>
            <div className="flex gap-4">
              <p className="mb-12">
                You can send a message here or via{" "}
                <a
                  className="font-bold text-blue cursor-pointer underline"
                  href="https://www.facebook.com/profile.php?id=61556814742222"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                Messenger; both methods work.
              </p>
            </div>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="flex flex-col gap-y-4"
            >
              <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-10">
                <input
                  className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                  type="text"
                  placeholder="Your name"
                  name="user_name"
                />
                <input
                  className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                  type="email"
                  placeholder="Your email address"
                  name="user_email"
                />
              </div>
              <input
                className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                type="text"
                placeholder="Your message"
                name="user_message"
              />
              <button className="btn mb-[30px] mx-auto lg:mx-0 self-start">
                Send Message
              </button>
              <Toaster />
            </form>
          </div>
          <div className="lg:flex-1">
            <motion.img
              whileHover={{ scale: 1.1 }}
              transition={transition1}
              src={ContactImg}
              alt=""
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;

import React from "react";
import TestimonialSlider from "./TestimonialSlider";

const Testimonials = () => {
  return (
    <section className="h-[364px] lg:mb-[120px]">
      <div className="container mx-auto">
        <div className="w-full mx-auto lg:mx-0 mg:max-w[570px]">
          <TestimonialSlider />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

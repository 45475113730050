import React from "react";
import { ImFacebook, ImInstagram } from "react-icons/im";

const Socials = () => {
  return (
    <div className="hidden xl:flex ml-24">
      <ul className="flex gap-4">
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=61556814742222"
            target="_blank"
          >
            <ImFacebook />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/roseenailstudio/" target="_blank">
            <ImInstagram />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;

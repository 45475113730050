import React from "react";
import messenger from "../img/sticky/messenger.png";

const StickyButton = ({ facebookPageId }) => {
  const messengerLink = `https://m.me/${facebookPageId}`;

  return (
    <a
      href={messengerLink}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-7 lg:right-4 right-[20px] text-white py-2 px-4 rounded shadow-lg hover:bg-gray-400 transition-colors flex items-center justify-center"
    >
      <img
        src={messenger}
        alt="Message Us"
        className="w-12 h-12" // Adjust width and height as needed
      />
    </a>
  );
};

export default StickyButton;

import ProfilePhoto1 from "../src/img/testimonials/ProfilePhoto1.jpg";
import ProfilePhoto2 from "../src/img/testimonials/ProfilePhoto2.jpg";
import ProfilePhoto3 from "../src/img/testimonials/ProfilePhoto3.jpg";
import ProfilePhoto4 from "../src/img/testimonials/ProfilePhoto4.jpg";
import ProfilePhoto5 from "../src/img/testimonials/ProfilePhoto5.jpg";
import ProfilePhoto6 from "../src/img/testimonials/ProfilePhoto6.jpg";

export const testimonials = [
  {
    text: "First time ko po mag tip sa isang nail tech! dasurv kasi super pretty niyaaaa, yung studio niya may aircon kaya di mainit plus malapit lang siya sa bahay, yung shape na gusto ko kuhang kuha walang mintis!! ang gaan pa ng kamay gusto ko din yung choice of color blue na napili 1000000000/10",
    name: "Joly J.",
    image: ProfilePhoto2,
  },
  {
    text: "Thankyouuuuu so much po ! mukha ng kamay ulit at kuha ung design na want ko.",
    name: "Kathrine S.",
    image: ProfilePhoto1,
  },

  {
    text: "hellooo! i love my nails ngayon huhu. worth it pagbyahe ko from Iba hehe tapos ang gaan din ng kamay mo! super antok ko kanina HAHAHAHA thank you ulit!",
    name: "Vanessa R. 3",
    image: ProfilePhoto3,
  },
  {
    text: "Grabe mam sobrang tibay. Sa 25 po 1 month na. Wala kahit isa natanggal. kahit laba, hugas plato, linis cr haha tibay.",
    name: "Erica R.",
    image: ProfilePhoto4,
  },
  {
    text: "sobrang tibay ng gawa mo. Minsan sinasadya ko na sya pwersahin pero ayaw talaga hahaha galing, love it.",
    name: "Liezl T.",
    image: ProfilePhoto5,
  },
  {
    text: "Thank youuu so much ateee gandang ganda ako sa nails ko now",
    name: "Gennifer M.",
    image: ProfilePhoto6,
  },
];

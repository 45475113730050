import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules"; // Import from 'swiper/modules'
import quote from "../img/testimonials/quote.png";
import { testimonials } from "../data";
import "./style.css";
const TestimonialSlider = () => {
  const safeTestimonials = Array.isArray(testimonials) ? testimonials : [];

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      pagination={{ dynamicBullets: true, clickable: true }}
      autoplay={{ delay: 5000 }}
    >
      {safeTestimonials.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="relative lg:mt-[34px] before:content-quote p-8">
            <img src={quote} className="w-[25px] h-[25px]" alt="Quote Icon" />
            <p>{item.text}</p>
            <div className="flex items-center mt-4">
              <img
                src={item.image}
                alt={item.name}
                className="w-12 h-12 rounded-full"
              />
              <div className="ml-4">
                <h6 className="font-bold">{item.name}</h6>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSlider;
